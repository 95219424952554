define("discourse/plugins/discourse-multilingual/discourse/models/multilingual-language", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MultilingualLanguage = _object.default.extend();
  const LanguagesPath = "/admin/multilingual/languages";
  function getParams() {
    const searchParams = new URLSearchParams(window.location.search);
    let params = {};
    for (let pair of searchParams.entries()) {
      if (["query", "order", "ascending"].indexOf(pair[0]) > -1) {
        params[pair[0]] = pair[1];
      }
    }
    return params;
  }
  MultilingualLanguage.reopenClass({
    list() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _ajax.ajax)(LanguagesPath, {
        data: Object.assign(getParams(), params)
      }).then(result => {
        return result.map(l => MultilingualLanguage.create(l));
      }).catch(_ajaxError.popupAjaxError);
    },
    save(languages) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      params = Object.assign(getParams(), params);
      let data = Object.assign({
        languages
      }, params);
      return (0, _ajax.ajax)(LanguagesPath, {
        method: "PUT",
        data: JSON.stringify(data),
        dataType: "json",
        contentType: "application/json"
      }).catch(_ajaxError.popupAjaxError);
    },
    remove(locales) {
      return (0, _ajax.ajax)(LanguagesPath, {
        method: "DELETE",
        data: Object.assign(getParams(), {
          locales
        })
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = MultilingualLanguage;
});