define("discourse/plugins/discourse-multilingual/discourse/components/language-switcher", ["exports", "discourse-common/helpers/i18n", "float-kit/components/d-menu", "discourse/plugins/discourse-multilingual/discourse/components/language-switcher-menu", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _i18n, _dMenu, _languageSwitcherMenu, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DMenu
      title={{i18n "user.locale.title"}}
      @icon="translate"
      id="multilingual-language-switcher"
      class="icon btn-flat"
    >
      <:content>
        <LanguageSwitcherMenu />
      </:content>
    </DMenu>
  
  */
  {
    "id": "A+2VQE7n",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[16,\"title\",[28,[32,1],[\"user.locale.title\"],null]],[24,1,\"multilingual-language-switcher\"],[24,0,\"icon btn-flat\"]],[[\"@icon\"],[\"translate\"]],[[\"content\"],[[[[1,\"\\n      \"],[8,[32,2],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-multilingual/discourse/components/language-switcher.js",
    "scope": () => [_dMenu.default, _i18n.default, _languageSwitcherMenu.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "language-switcher"));
});