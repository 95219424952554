define("discourse/plugins/discourse-multilingual/discourse/components/admin-translation", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/plugins/discourse-multilingual/discourse/models/multilingual-translation"], function (_exports, _component, _tracking, _object, _multilingualTranslation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminTranslation extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "removing", [_tracking.tracked], function () {
      return false;
    }))();
    #removing = (() => (dt7948.i(this, "removing"), void 0))();
    remove() {
      this.removing = true;
      _multilingualTranslation.default.remove(this.args.translation.locale, this.args.translation.file_type).then(() => {
        this.removing = false;
        this.args.removed();
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "remove", [_object.action]))();
    download() {
      _multilingualTranslation.default.download(this.args.translation.locale, this.args.translation.file_type);
    }
    static #_3 = (() => dt7948.n(this.prototype, "download", [_object.action]))();
  }
  _exports.default = AdminTranslation;
});