define("discourse/plugins/discourse-multilingual/discourse/templates/admin-multilingual", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-controls">
    <nav>
      <ul class="nav nav-pills">
        {{nav-item
          route="adminMultilingualLanguages"
          label="multilingual.languages.title"
        }}
        {{nav-item
          route="adminMultilingualTranslations"
          label="multilingual.translations.title"
        }}
        {{nav-item
          route="tagGroups.edit"
          routeParam=this.tagGroupId
          label="tagging.tags"
          icon="tag"
        }}
        <a
          href={{this.documentationUrl}}
          target="_blank"
          class="right btn btn-icon-text"
          rel="noreferrer noopener"
        >
          {{d-icon "file-alt"}}
          <span class="d-button-label">{{i18n
              "multilingual.documentation"
            }}</span>
        </a>
        <li>
          {{d-button
            action=(route-action "showSettings")
            label="admin.plugins.change_settings_short"
            icon="cog"
          }}
        </li>
      </ul>
    </nav>
  </div>
  
  {{outlet}}
  */
  {
    "id": "PMhb0p7P",
    "block": "[[[10,0],[14,0,\"admin-controls\"],[12],[1,\"\\n  \"],[10,\"nav\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n      \"],[1,[28,[35,0],null,[[\"route\",\"label\"],[\"adminMultilingualLanguages\",\"multilingual.languages.title\"]]]],[1,\"\\n      \"],[1,[28,[35,0],null,[[\"route\",\"label\"],[\"adminMultilingualTranslations\",\"multilingual.translations.title\"]]]],[1,\"\\n      \"],[1,[28,[35,0],null,[[\"route\",\"routeParam\",\"label\",\"icon\"],[\"tagGroups.edit\",[30,0,[\"tagGroupId\"]],\"tagging.tags\",\"tag\"]]]],[1,\"\\n      \"],[10,3],[15,6,[30,0,[\"documentationUrl\"]]],[14,\"target\",\"_blank\"],[14,0,\"right btn btn-icon-text\"],[14,\"rel\",\"noreferrer noopener\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"file-alt\"],null]],[1,\"\\n        \"],[10,1],[14,0,\"d-button-label\"],[12],[1,[28,[35,2],[\"multilingual.documentation\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"action\",\"label\",\"icon\"],[[28,[37,4],[\"showSettings\"],null],\"admin.plugins.change_settings_short\",\"cog\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,6],null,null],null,null,null]],[],false,[\"nav-item\",\"d-icon\",\"i18n\",\"d-button\",\"route-action\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/admin-multilingual.hbs",
    "isStrictMode": false
  });
});