define("discourse/plugins/discourse-multilingual/select-kit/components/content-languages-row", ["exports", "discourse/lib/url", "select-kit/components/select-kit/select-kit-row", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route"], function (_exports, _url, _selectKitRow, _multilingualRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _selectKitRow.default.extend({
    click(e) {
      if (this.rowValue === "set_content_language") {
        _url.default.routeTo((0, _url.userPath)(this.currentUser.username_lower + "/preferences/interface"));
      } else if (this.item.icon === "times") {
        (0, _multilingualRoute.addParam)(_multilingualRoute.contentLanguageParam, null, {
          ctx: this
        });
      } else if (!this.currentUser) {
        (0, _multilingualRoute.addParam)(_multilingualRoute.contentLanguageParam, this.rowValue, {
          ctx: this
        });
      } else {
        this._super(e);
      }
    }
  });
});