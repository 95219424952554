define("discourse/plugins/discourse-multilingual/discourse/templates/components/admin-translation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class="translation">
    <td class="locale">
      {{@translation.locale}}
    </td>
  
    <td class="types">
      {{@translation.file_type}}
    </td>
  
    <td class="actions">
      <DButton @action={{this.remove}}>
        {{#if this.removing}}
          {{loading-spinner size="small"}}
        {{else}}
          {{d-icon "times"}}
          {{i18n "multilingual.translations.remove"}}
        {{/if}}
      </DButton>
    </td>
  </tr>
  */
  {
    "id": "K2UO/LA2",
    "block": "[[[10,\"tr\"],[14,0,\"translation\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"locale\"],[12],[1,\"\\n    \"],[1,[30,1,[\"locale\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[14,0,\"types\"],[12],[1,\"\\n    \"],[1,[30,1,[\"file_type\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[14,0,\"actions\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@action\"],[[30,0,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"removing\"]],[[[1,\"        \"],[1,[28,[35,2],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,3],[\"times\"],null]],[1,\"\\n        \"],[1,[28,[35,4],[\"multilingual.translations.remove\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@translation\"],false,[\"d-button\",\"if\",\"loading-spinner\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/components/admin-translation.hbs",
    "isStrictMode": false
  });
});