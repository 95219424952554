define("discourse/plugins/discourse-multilingual/discourse/templates/connectors/composer-fields/composer-language-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showSelector}}
    {{content-languages-selector
      content=this.site.content_languages
      value=this.model.content_language_tags
      filterPlaceholder="topic.content_languages.placeholder"
      onChange=(action (mut this.model.content_language_tags))
    }}
  {{/if}}
  */
  {
    "id": "rAi6AKF1",
    "block": "[[[41,[30,0,[\"showSelector\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"content\",\"value\",\"filterPlaceholder\",\"onChange\"],[[30,0,[\"site\",\"content_languages\"]],[30,0,[\"model\",\"content_language_tags\"]],\"topic.content_languages.placeholder\",[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"model\",\"content_language_tags\"]]],null]],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"content-languages-selector\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/connectors/composer-fields/composer-language-selector.hbs",
    "isStrictMode": false
  });
});