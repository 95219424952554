define("discourse/plugins/discourse-multilingual/discourse/widgets/language-switcher-menu", ["exports", "@ember/runloop", "jquery", "virtual-dom", "discourse/widgets/widget", "I18n", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route"], function (_exports, _runloop, _jquery, _virtualDom, _widget, _I18n, _multilingualRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("language-switcher-menu", {
    tagName: "div.language-switcher-menu",
    buildKey: () => "language-switcher-menu",
    settings: {
      maxWidth: 320
    },
    defaultState() {
      return {
        available: this.site.interface_languages
      };
    },
    panelContents() {
      const {
        available
      } = this.state;
      const currentLocale = _I18n.default.currentLocale();
      return (0, _virtualDom.h)("ul", available.map(l => {
        let className = "ls-language";
        if (l.locale === currentLocale) {
          className += " current";
        }
        return (0, _virtualDom.h)("li", this.attach("link", {
          className,
          action: "change",
          actionParam: l.locale,
          rawLabel: l.name
        }));
      }));
    },
    change(locale) {
      (0, _multilingualRoute.addParam)(_multilingualRoute.localeParam, locale, {
        add_cookie: true,
        ctx: this
      });
    },
    html() {
      return this.attach("menu-panel", {
        maxWidth: this.settings.maxWidth,
        contents: () => this.panelContents()
      });
    },
    clickOutsideMobile(e) {
      const $centeredElement = (0, _jquery.default)(document.elementFromPoint(e.clientX, e.clientY));
      if ($centeredElement.parents(".panel").length && !$centeredElement.hasClass("header-cloak")) {
        this.sendWidgetAction("toggleLangugeSwitcherMenu");
      } else {
        const $window = (0, _jquery.default)(window);
        const windowWidth = $window.width();
        const $panel = (0, _jquery.default)(".menu-panel");
        $panel.addClass("animate");
        $panel.css("right", -windowWidth);
        const $headerCloak = (0, _jquery.default)(".header-cloak");
        $headerCloak.addClass("animate");
        $headerCloak.css("opacity", 0);
        (0, _runloop.later)(() => this.sendWidgetAction("toggleLangugeSwitcherMenu"), 200);
      }
    },
    clickOutside(e) {
      if (this.site.mobileView) {
        this.clickOutsideMobile(e);
      } else {
        this.sendWidgetAction("toggleLangugeSwitcherMenu");
      }
    }
  });
});