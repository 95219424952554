define("discourse/plugins/discourse-multilingual/discourse/templates/tag-groups-edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.content_language_group}}
    {{content-tag-groups-form
      model=this.model
      onDestroy=(action "onDestroy")
      tagsChanged=(route-action "tagsChanged")
    }}
  {{else}}
    {{tag-groups-form model=this.model onDestroy=(action "onDestroy")}}
  {{/if}}
  */
  {
    "id": "7YtaKzc9",
    "block": "[[[41,[30,0,[\"model\",\"content_language_group\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"model\",\"onDestroy\",\"tagsChanged\"],[[30,0,[\"model\"]],[28,[37,2],[[30,0],\"onDestroy\"],null],[28,[37,3],[\"tagsChanged\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,4],null,[[\"model\",\"onDestroy\"],[[30,0,[\"model\"]],[28,[37,2],[[30,0],\"onDestroy\"],null]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"content-tag-groups-form\",\"action\",\"route-action\",\"tag-groups-form\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/tag-groups-edit.hbs",
    "isStrictMode": false
  });
});