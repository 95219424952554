define("discourse/plugins/discourse-multilingual/discourse/templates/connectors/user-preferences-interface/content-language", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group content-languages">
    <label class="control-label">
      {{i18n "user.content_languages.title"}}
    </label>
    <div class="controls content-languages-selector-container">
      {{content-languages-selector
        content=this.site.content_languages
        value=this.model.custom_fields.content_languages
        noneLabel="user.content_languages.placeholder"
        onChange=(action (mut this.model.custom_fields.content_languages))
        initializeContentLanguges=false
      }}
    </div>
    <div class="instructions">
      {{i18n "user.content_languages.instructions"}}
    </div>
  </div>
  */
  {
    "id": "UkpZkM5z",
    "block": "[[[10,0],[14,0,\"control-group content-languages\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"user.content_languages.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls content-languages-selector-container\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"content\",\"value\",\"noneLabel\",\"onChange\",\"initializeContentLanguges\"],[[30,0,[\"site\",\"content_languages\"]],[30,0,[\"model\",\"custom_fields\",\"content_languages\"]],\"user.content_languages.placeholder\",[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"model\",\"custom_fields\",\"content_languages\"]]],null]],null],false]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"user.content_languages.instructions\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"content-languages-selector\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/connectors/user-preferences-interface/content-language.hbs",
    "isStrictMode": false
  });
});