define("discourse/plugins/discourse-multilingual/discourse/templates/connectors/edit-topic/topic-language-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{content-languages-selector
    content=this.site.content_languages
    value=this.buffered.content_language_tags
    filterPlaceholder="topic.content_languages.placeholder"
  }}
  */
  {
    "id": "k2P+td+7",
    "block": "[[[1,[28,[35,0],null,[[\"content\",\"value\",\"filterPlaceholder\"],[[30,0,[\"site\",\"content_languages\"]],[30,0,[\"buffered\",\"content_language_tags\"]],\"topic.content_languages.placeholder\"]]]]],[],false,[\"content-languages-selector\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/connectors/edit-topic/topic-language-selector.hbs",
    "isStrictMode": false
  });
});