define("discourse/plugins/discourse-multilingual/discourse/components/admin-multilingual-translations", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/plugins/discourse-multilingual/discourse/models/multilingual-translation"], function (_exports, _component, _tracking, _object, _multilingualTranslation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminMultilingualTranslations extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "refreshing", [_tracking.tracked], function () {
      return false;
    }))();
    #refreshing = (() => (dt7948.i(this, "refreshing"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "translations", [_tracking.tracked], function () {
      return [];
    }))();
    #translations = (() => (dt7948.i(this, "translations"), void 0))();
    constructor() {
      super(...arguments);
      this._refresh();
    }
    _refresh() {
      this.refreshing = true;
      _multilingualTranslation.default.list().then(result => {
        this.translations = result;
      }).finally(() => {
        this.refreshing = false;
      });
    }
    refresh() {
      this._refresh();
    }
    static #_3 = (() => dt7948.n(this.prototype, "refresh", [_object.action]))();
  }
  _exports.default = AdminMultilingualTranslations;
});