define("discourse/plugins/discourse-multilingual/discourse/connectors/user-preferences-interface/content-language", ["exports", "@ember/runloop", "jquery"], function (_exports, _runloop, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.multilingual_enabled && ctx.siteSettings.multilingual_content_languages_enabled && ctx.siteSettings.multilingual_content_languages_topic_filtering_enabled;
    },
    setupComponent() {
      (0, _runloop.schedule)("afterRender", () => {
        const content = ".control-group.content-languages";
        const int = ".control-group.pref-locale";
        const text = ".control-group.text-size";
        const form = ".user-preferences form";
        if ((0, _jquery.default)(text).length && !(0, _jquery.default)(form).children(content).length) {
          (0, _jquery.default)(content).prependTo(form);
        }
        if (!(0, _jquery.default)(content).next(int).length) {
          (0, _jquery.default)(int).insertAfter(content);
        }
      });
    }
  };
});