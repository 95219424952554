define("discourse/plugins/discourse-multilingual/discourse/templates/connectors/admin-menu/multilingual-nav-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.admin}}
    {{nav-item route="adminMultilingual" label="multilingual.label"}}
  {{/if}}
  */
  {
    "id": "Hq8dnxpg",
    "block": "[[[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"adminMultilingual\",\"multilingual.label\"]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"nav-item\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/connectors/admin-menu/multilingual-nav-button.hbs",
    "isStrictMode": false
  });
});