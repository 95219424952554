define("discourse/plugins/discourse-multilingual/discourse/controllers/admin-multilingual-languages", ["exports", "@ember/array", "@ember/controller", "@ember/object/computed", "@ember/runloop", "discourse/lib/computed", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-multilingual/discourse/models/multilingual-language"], function (_exports, _array, _controller, _computed, _runloop, _computed2, _debounce, _decorators, _I18n, _multilingualLanguage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    refreshing: false,
    queryPlaceholder: (0, _computed2.i18n)("multilingual.languages.query_placeholder"),
    updateState: "save",
    languages: [],
    updatedLanguages: (0, _array.A)(),
    anyLanguages: (0, _computed.notEmpty)("filteredLanguages"),
    title() {
      return _I18n.default.t("multilingual.languages.title");
    },
    setupObservers() {
      this.addObserver("query", this._filterLanguages);
      this.addObserver("ascending", this._filterLanguages);
      this.addObserver("order", this._filterLanguages);
    },
    _filterLanguages() {
      // TODO: Use discouseDebounce when discourse 2.7 gets released.
      let debounceFunc = _debounce.default || _runloop.debounce;
      debounceFunc(this, this._refreshLanguages, 250);
    },
    updateLanguagesDisabled(updatedLanguages, updateState) {
      return updatedLanguages.length === 0 || updateState !== "save";
    },
    filteredLanguages(languages, customOnly) {
      if (customOnly) {
        return languages.filter(l => l.custom);
      }
      return languages;
    },
    _updateLanguages(languages) {
      this.setProperties({
        updatedLanguages: (0, _array.A)(),
        languages
      });
    },
    _refreshLanguages() {
      this.set("refreshing", true);
      let params = {};
      ["query", "ascending", "order"].forEach(p => {
        let val = this.get(p);
        if (val) {
          params[p] = val;
        }
      });
      _multilingualLanguage.default.list(params).then(result => {
        this._updateLanguages(result);
      }).finally(() => {
        this.set("refreshing", false);
      });
    },
    actions: {
      refreshLanguages() {
        this._refreshLanguages();
      },
      update() {
        if (this.updateLanguagesDisabled) {
          return;
        }
        this.set("updateState", "saving");
        _multilingualLanguage.default.save(this.updatedLanguages).then(result => {
          this._updateLanguages(result);
          this.set("updateState", "saved");
          setTimeout(() => {
            this.set("updateState", "save");
          }, 4000);
        });
      },
      updateLanguages(languages) {
        this._updateLanguages(languages);
      },
      languagesUploaded() {
        this.set("customOnly", true);
        this._refreshLanguages();
      }
    }
  }, [["method", "title", [_decorators.default]], ["method", "updateLanguagesDisabled", [(0, _decorators.default)("updatedLanguages.[]", "updateState")]], ["method", "filteredLanguages", [(0, _decorators.default)("languages.[]", "customOnly")]]]));
});