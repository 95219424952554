define("discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route", ["exports", "@ember/runloop", "discourse/lib/cookie", "discourse/lib/url", "discourse-common/lib/get-owner"], function (_exports, _runloop, _cookie, _url, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addParam = addParam;
  _exports.discoveryParams = _exports.contentLanguageParam = void 0;
  _exports.getDiscoveryParam = getDiscoveryParam;
  _exports.getParams = getParams;
  _exports.getRouter = getRouter;
  _exports.localeParam = void 0;
  _exports.removeParam = removeParam;
  _exports.setDiscoveryParam = setDiscoveryParam;
  const contentLanguageParam = _exports.contentLanguageParam = "content_languages";
  const localeParam = _exports.localeParam = "locale";
  const discoveryParams = _exports.discoveryParams = [contentLanguageParam];
  function getRouter(ctx) {
    const router = (0, _getOwner.getOwner)(ctx).lookup("service:router");
    return router;
  }
  function getDiscoveryController(ctx) {
    const controller = (0, _getOwner.getOwner)(ctx).lookup(`controller:discovery/topics`);
    return controller;
  }
  function getDiscoveryParam(ctx, paramName) {
    return getDiscoveryController(ctx).get(paramName);
  }
  function setDiscoveryParam(ctx, paramName, value) {
    getDiscoveryController(ctx).set(paramName, value);
  }
  function getPath(ctx) {
    const path = getRouter(ctx).currentURL.toString();
    return path;
  }
  function getParams(ctx) {
    let paramName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    let parts = getPath(ctx).split("?");
    let queryParams = parts.length > 1 ? parts[1] : "";
    let params = new URLSearchParams(queryParams);
    return paramName ? params.get(paramName) : params;
  }
  function buildPath(ctx, params) {
    let parts = getPath(ctx).split("?");
    parts[1] = params.toString();
    return parts.filter(p => p.length).join("?");
  }
  function useDiscoveryController(ctx, paramName) {
    return getRouter(ctx).currentRouteName.indexOf("discovery") > -1 && discoveryParams.indexOf(paramName) > -1;
  }

  // same as DiscourseURL.replaceState besides allowing modification of current path
  function replaceState(path) {
    if (window.history && window.history.pushState && window.history.replaceState) {
      (0, _runloop.next)(() => {
        const location = _url.default.get("router.location");
        if (location && location.replaceURL) {
          location.replaceURL(path);
        }
      });
    }
  }
  function addParam(paramName, value) {
    let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    if (opts.add_cookie) {
      (0, _cookie.default)(`discourse_${paramName}`, value);
    }
    if (useDiscoveryController(opts.ctx, paramName)) {
      return setDiscoveryParam(opts.ctx, paramName, value);
    }
    const params = getParams(opts.ctx);
    params.delete(paramName);
    if (value) {
      params.set(paramName, value);
    }
    window.location.href = buildPath(opts.ctx, params);
    return value;
  }
  function removeParam(paramName) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const params = getParams(opts.ctx);
    let value = params.get(paramName);
    if (!value) {
      return null;
    }
    if (useDiscoveryController(opts.ctx, paramName)) {
      return setDiscoveryParam(opts.ctx, null);
    }
    params.delete(paramName);
    replaceState(buildPath(opts.ctx, params));
    return value;
  }
});