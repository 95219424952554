define("discourse/plugins/discourse-multilingual/discourse/components/content-tag-groups-form", ["exports", "@ember/service", "discourse/components/tag-groups-form", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n"], function (_exports, _service, _tagGroupsForm, _ajax, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _tagGroupsForm.default.extend({
    dialog: (0, _service.service)(),
    updateContentTags() {
      this.set("changingContentTags", _I18n.default.t("tagging.groups.content_tags.update.message"));
      (0, _ajax.ajax)(`/tag_groups/${this.model.id}/content-tags`, {
        type: "PUT"
      }).catch(_ajaxError.popupAjaxError).then(() => this.set("changingContentTags", null)).finally(() => this.tagsChanged());
    },
    destroyContentTags() {
      this.set("changingContentTags", _I18n.default.t("tagging.groups.content_tags.delete.message"));
      (0, _ajax.ajax)(`/tag_groups/${this.model.id}/content-tags`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError).then(() => this.set("changingContentTags", null)).finally(() => this.tagsChanged());
    },
    actions: {
      destroyContentTags() {
        this.dialog.deleteConfirm({
          title: _I18n.default.t("tagging.groups.content_tags.delete.confirm"),
          didConfirm: () => this.destroyContentTags()
        });
      },
      updateContentTags() {
        this.updateContentTags();
      }
    }
  });
});