define("discourse/plugins/discourse-multilingual/discourse/templates/components/discourse-tag-bound", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{discourse-tag this.tagRecord.id}}
  */
  {
    "id": "ck/qen9l",
    "block": "[[[1,[28,[35,0],[[30,0,[\"tagRecord\",\"id\"]]],null]]],[],false,[\"discourse-tag\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/components/discourse-tag-bound.hbs",
    "isStrictMode": false
  });
});