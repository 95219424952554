define("discourse/plugins/discourse-multilingual/discourse/templates/components/legacy-table-header-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="header-contents" id={{this.id}}>
    {{directory-table-header-title
      field=this.field
      labelKey=this.labelKey
      icon=this.icon
      translated=this.translated
    }}
    {{this.chevronIcon}}
  </span>
  
  {{yield}}
  */
  {
    "id": "fA9Vv2jD",
    "block": "[[[10,1],[14,0,\"header-contents\"],[15,1,[30,0,[\"id\"]]],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"field\",\"labelKey\",\"icon\",\"translated\"],[[30,0,[\"field\"]],[30,0,[\"labelKey\"]],[30,0,[\"icon\"]],[30,0,[\"translated\"]]]]]],[1,\"\\n  \"],[1,[30,0,[\"chevronIcon\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,1,null]],[\"&default\"],false,[\"directory-table-header-title\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/components/legacy-table-header-toggle.hbs",
    "isStrictMode": false
  });
});