define("discourse/plugins/discourse-multilingual/discourse/lib/multilingual-tag", ["exports", "discourse/lib/utilities", "discourse/models/user", "discourse-common/lib/get-url", "discourse-common/lib/helpers", "I18n", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual"], function (_exports, _utilities, _user, _getUrl, _helpers, _I18n, _multilingual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multilingualTagRenderer = multilingualTagRenderer;
  _exports.multilingualTagTranslator = multilingualTagTranslator;
  function multilingualTagRenderer(tag, params) {
    params = params || {};
    const siteSettings = (0, _helpers.helperContext)().siteSettings;
    const clt = (0, _multilingual.isContentLanguage)(tag, siteSettings);
    if (clt && !params.contentLanguageTag) {
      return "";
    }
    tag = (0, _utilities.escapeExpression)(tag).toLowerCase();
    const translatedTag = multilingualTagTranslator(tag);
    const visibleName = clt ? clt.name : translatedTag;
    const classes = ["discourse-tag"];
    const tagName = params.tagName || "a";
    let path;
    if (tagName === "a" && !params.noHref) {
      if ((params.isPrivateMessage || params.pmOnly) && _user.default.current()) {
        const username = params.tagsForUser ? params.tagsForUser : _user.default.current().username;
        path = `/u/${username}/messages/tags/${tag}`;
      } else {
        path = `/tag/${tag}`;
      }
    }
    const href = path ? ` href='${(0, _getUrl.default)(path)}' ` : "";
    if (siteSettings.tag_style || params.style) {
      classes.push(params.style || siteSettings.tag_style);
    }
    let val = "<" + tagName + href + " data-tag-name=" + tag + " class='" + classes.join(" ") + "'>" + visibleName + "</" + tagName + ">";
    if (params.count) {
      val += " <span class='discourse-tag-count'>x" + params.count + "</span>";
    }
    return val;
  }
  function multilingualTagTranslator(tag) {
    if (typeof _I18n.default.tag_translations !== "undefined" && _I18n.default.tag_translations !== null && typeof _I18n.default.tag_translations[_I18n.default.currentLocale()] !== "undefined" && typeof _I18n.default.tag_translations[_I18n.default.currentLocale()][tag] !== "undefined") {
      return _I18n.default.tag_translations[_I18n.default.currentLocale()][tag];
    } else {
      return tag;
    }
  }
});