define("discourse/plugins/discourse-multilingual/discourse/templates/admin-multilingual-translations", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminMultilingualTranslations />
  */
  {
    "id": "y0fHHc6p",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"admin-multilingual-translations\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/admin-multilingual-translations.hbs",
    "isStrictMode": false
  });
});