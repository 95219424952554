define("discourse/plugins/discourse-multilingual/discourse/models/multilingual-translation", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MultilingualTranslation = _object.default.extend();
  const TranslationPath = "/admin/multilingual/translations";
  MultilingualTranslation.reopenClass({
    list() {
      return (0, _ajax.ajax)(TranslationPath).catch(_ajaxError.popupAjaxError);
    },
    remove(locale, file_type) {
      return (0, _ajax.ajax)(TranslationPath, {
        method: "DELETE",
        data: {
          locale,
          file_type
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    download(locale, file_type) {
      return (0, _ajax.ajax)(TranslationPath + "/download", {
        data: {
          locale,
          file_type
        },
        xhrFields: {
          responseType: "blob"
        }
      });
    }
  });
  var _default = _exports.default = MultilingualTranslation;
});