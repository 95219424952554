define("discourse/plugins/discourse-multilingual/discourse/components/language-switcher-bar", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "jquery", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route"], function (_exports, _component, _object, _computed, _runloop, _jquery, _decorators, _I18n, _multilingualRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "language-switcher-bar",
    showHidden: false,
    showHiddenToggle: (0, _computed.notEmpty)("hiddenLanguages"),
    setup() {
      const availableLanguages = this.availableLanguages();
      const currentLanguage = _I18n.default.currentLocale();
      let visibleList = this.siteSettings.multilingual_guest_language_switcher_footer_visible.split("|");
      availableLanguages.forEach(l => {
        if (l.locale === currentLanguage) {
          l.set("class", `${l.class} current`);
          if (visibleList.indexOf(l.locale) === -1) {
            visibleList.pop();
            visibleList.unshift(l.locale);
          }
        }
      });
      const visibleLimit = this.site.mobileView ? 3 : 10;
      let visibleLanguages = [];
      let hiddenLanguages = [];
      availableLanguages.forEach(l => {
        if (visibleList.indexOf(l.locale) > -1 && visibleLanguages.length < visibleLimit) {
          visibleLanguages.push(l);
        } else {
          hiddenLanguages.push(l);
        }
      });
      this.setProperties({
        visibleLanguages,
        hiddenLanguages
      });
    },
    availableLanguages() {
      return this.site.interface_languages.map(l => {
        return _object.default.create(Object.assign({}, l, {
          class: "language"
        }));
      });
    },
    didInsertElement() {
      this._super(...arguments);
      this.set("clickOutsideHandler", (0, _runloop.bind)(this, this.clickOutside));
      (0, _jquery.default)(document).on("click", this.clickOutsideHandler);
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _jquery.default)(document).off("click", this.clickOutsideHandler);
    },
    clickOutside(e) {
      const $hidden = (0, _jquery.default)(".language-switcher-bar .hidden-languages");
      const $target = (0, _jquery.default)(e.target);
      if (!$target.closest($hidden).length) {
        this.set("showHidden", false);
      }
    },
    actions: {
      change(locale) {
        this.set("showHidden", false);
        (0, _multilingualRoute.addParam)(_multilingualRoute.localeParam, locale, {
          add_cookie: true,
          ctx: this
        });
      },
      toggleHidden() {
        this.toggleProperty("showHidden");
      }
    }
  }, [["method", "setup", [(0, _decorators.on)("init")]]]));
});