define("discourse/plugins/discourse-multilingual/discourse/components/admin-language", ["exports", "@ember/component", "discourse-common/lib/object", "discourse-common/utils/decorators", "discourse/plugins/discourse-multilingual/discourse/models/multilingual-language"], function (_exports, _component, _object, _decorators, _multilingualLanguage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "tr",
    classNames: "language",
    didInsertElement() {
      this._super(...arguments);
      this.currentLanguage = JSON.parse(JSON.stringify(this.language));
    },
    trackUpdates() {
      if ((0, _object.deepEqual)(this.currentLanguage, this.language)) {
        this.updatedLanguages.removeObject(this.language);
      } else {
        this.updatedLanguages.addObject(this.language);
      }
    },
    typeKey(custom) {
      return `multilingual.languages.${custom ? "custom" : "base"}`;
    },
    interfaceToggleDisabled(locale) {
      return locale === "en";
    },
    contentDisabled(tagConflict) {
      return !this.siteSettings.multilingual_content_languages_enabled || tagConflict;
    },
    interfaceDisabled() {
      return !this.siteSettings.allow_user_locale;
    },
    actionsDisabled(custom) {
      return !custom;
    },
    contentClass() {
      return this.generateControlColumnClass("content");
    },
    interfaceClass() {
      return this.generateControlColumnClass("interface");
    },
    actionsClass() {
      return this.generateControlColumnClass("actions");
    },
    generateControlColumnClass(type) {
      let columnClass = `language-control ${type}`;
      if (this.get(`${type}Disabled`)) {
        columnClass += " disabled";
      }
      return columnClass;
    },
    actions: {
      remove() {
        this.set("removing", true);
        let locales = [this.get("language.locale")];
        _multilingualLanguage.default.remove(locales).then(result => {
          this.set("removing", false);
          this.removed(result);
        });
      }
    }
  }, [["method", "trackUpdates", [(0, _decorators.observes)("language.content_enabled", "language.interface_enabled")]], ["method", "typeKey", [(0, _decorators.default)("language.custom")]], ["method", "interfaceToggleDisabled", [(0, _decorators.default)("language.locale")]], ["method", "contentDisabled", [(0, _decorators.default)("language.content_tag_conflict")]], ["method", "interfaceDisabled", [_decorators.default]], ["method", "actionsDisabled", [(0, _decorators.default)("language.custom")]], ["method", "contentClass", [_decorators.default]], ["method", "interfaceClass", [_decorators.default]], ["method", "actionsClass", [_decorators.default]]]));
});